import React, { useState, useEffect } from 'react';
import Web3 from 'web3';

const ExchangeForm = ({ walletAddress }) => {
  const [bidzAmount, setBidzAmount] = useState('');
  const [userId, setUserId] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [dailyLimitReached, setDailyLimitReached] = useState(false); // New state to track if the daily limit is reached

  const web3 = new Web3(window.ethereum);

  const companyAddress = '0xfc4B0378e8eAD18DBFce579a01599a5654bCCBe8';
  const bidzTokenAddress = '0x20de6118c3672659e488d1d45279cdf77391fbdc';

  const bidzTokenAbi = [
    {
      constant: false,
      inputs: [
        { name: 'recipient', type: 'address' },
        { name: 'amount', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function'
    }
  ];

  useEffect(() => {
    const fetchUserIdAndLimits = async () => {
      try {
        const userResponse = await fetch(`http://92.204.129.69:3007/api/exchange/user?bidzWallet=${walletAddress}`);
        const userData = await userResponse.json();

        if (userData && userData.userId) {
          setUserId(userData.userId);

          // Fetch the user's exchange limits and exchanged amounts
          const limitsResponse = await fetch(`http://92.204.129.69:3007/api/exchange/exchange-limits?userId=${userData.userId}`);
          const limitsData = await limitsResponse.json();

          if (limitsData) {
            // Check if the user has reached their daily limit
            if (limitsData.daily_exchanged >= limitsData.daily_limit) {
              setDailyLimitReached(true);
            }
          }
        } else {
          setResponseMessage('User not found for this wallet address.');
        }
      } catch (error) {
        console.error('Error fetching user ID or exchange limits:', error);
        setResponseMessage('Failed to fetch user ID or exchange limits.');
      }
    };

    fetchUserIdAndLimits();
  }, [walletAddress]);

  const fetchTransactionHistory = async (userId) => {
    try {
      const historyResponse = await fetch(`http://92.204.129.69:3007/api/exchange/transaction-history?userId=${userId}`);
      const historyData = await historyResponse.json();

      if (historyData && Array.isArray(historyData)) {
        setTransactionHistory(historyData);
      } else {
        setResponseMessage('No transaction history found.');
      }
    } catch (error) {
      console.error('Error fetching transaction history:', error);
      setResponseMessage('Failed to fetch transaction history.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (dailyLimitReached) {
      setResponseMessage("You can't exchange because the daily exchange limit has been reached.");
      return;
    }

    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
      const account = accounts[0];
      const bidzContract = new web3.eth.Contract(bidzTokenAbi, bidzTokenAddress);
      const bidzAmountInWei = web3.utils.toWei(bidzAmount.toString(), 'ether');

      await bidzContract.methods.transfer(companyAddress, bidzAmountInWei)
        .send({ from: account, gas: 100000 })
        .on('transactionHash', async (hash) => {
          setResponseMessage(`Transaction submitted. Hash: ${hash}`);

          try {
            const response = await fetch('http://92.204.129.69:3007/api/exchange/convert', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                userId,
                bidz_wallet: account,
                bidzAmount: parseFloat(bidzAmount),
                transactionHash: hash,
              }),
            });

            const responseData = await response.json();
            if (response.ok) {
              setResponseMessage(`Exchange successful! You have transferred ${bidzAmount} BIDZ.`);
              fetchTransactionHistory(userId);
            } else {
              setResponseMessage(`Error: ${responseData.error}`);
            }
          } catch (error) {
            setResponseMessage('Error notifying backend after transaction.');
          }
        })
        .on('error', (error) => {
          setResponseMessage('Transaction failed.');
          console.error('Transaction Error:', error);
        });
    } catch (error) {
      console.error('Error exchanging BIDZ:', error);
      setResponseMessage('An error occurred during the exchange. Please try again.');
    }
  };

  const shortenAddress = (address) => {
    return `${address.slice(0, 5)}...${address.slice(-4)}`;
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="max-w-lg mx-auto bg-white dark:bg-slate-800 rounded-lg shadow-lg p-6">
      <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-4">Exchange BIDZ for USD</h2>
      {walletAddress && <p className="text-sm text-gray-500 dark:text-gray-400">Connected Wallet: {walletAddress}</p>}
      
      {dailyLimitReached ? (
        <p className="text-center text-red-500 mt-4">You can't exchange because the daily exchange limit has been reached.</p>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="bidzAmount" className="block text-sm font-medium text-gray-700 dark:text-gray-300">BIDZ Amount:</label>
            <input
              id="bidzAmount"
              type="number"
              step="0.01"
              value={bidzAmount}
              onChange={(e) => setBidzAmount(e.target.value)}
              required
              className="block w-full px-3 py-2 bg-gray-100 dark:bg-slate-900 text-gray-900 dark:text-white rounded-md shadow-sm border border-gray-300 dark:border-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            />
          </div>
          <button type="submit" className="w-full bg-violet-600 text-white py-2 rounded-md hover:bg-violet-700 transition">
            Exchange
          </button>
        </form>
      )}
      
      {responseMessage && <p className="text-center text-sm text-red-500 mt-2">{responseMessage}</p>}

      {transactionHistory.length > 0 && (
        <div className="mt-6">
          <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-2">Transaction History</h3>
          <div className="grid gap-4">
            {transactionHistory.slice(0, 5).map((transaction, index) => (
              <div key={index} className="p-4 bg-gray-100 dark:bg-slate-700 rounded-lg shadow">
                <p>Tx Hash: <a href={`https://bscscan.com/tx/${transaction.transaction_hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">View</a></p>
                <p>From: {shortenAddress(transaction.transaction_from)}</p>
                <p>To: {shortenAddress(transaction.transaction_to)}</p>
                <p>BIDZ: {transaction.bidz_coin}</p>
              </div>
            ))}
          </div>
          
          {transactionHistory.length > 5 && (
            <button onClick={toggleModal} className="mt-4 w-full text-center text-sm text-violet-600 hover:underline">
              Show More
            </button>
          )}

          {showModal && (
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white dark:bg-slate-900 p-6 rounded-md shadow-lg max-w-3xl w-full">
                <h3 className="text-lg font-medium text-gray-900 dark:text-white mb-4">All Transactions</h3>
                <button onClick={toggleModal} className="absolute top-4 right-4 text-gray-600 dark:text-gray-400">&times;</button>
                <div className="grid gap-4">
                  {transactionHistory.map((transaction, index) => (
                    <div key={index} className="p-4 bg-gray-100 dark:bg-slate-700 rounded-lg shadow">
                      <p>Tx Hash: <a href={`https://bscscan.com/tx/${transaction.transaction_hash}`} target="_blank" rel="noopener noreferrer" className="text-blue-500">View</a></p>
                      <p>From: {shortenAddress(transaction.transaction_from)}</p>
                      <p>To: {shortenAddress(transaction.transaction_to)}</p>
                      <p>BIDZ: {transaction.bidz_coin}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExchangeForm;