import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import appStoreImg from '../assets/appstore.webp'; // replace with the path to App Store image
import playStoreImg from '../assets/google.webp'; // replace with the path to Play Store image
import bidzLogo from '../assets/logo.png'; // replace with the path to BIDZ logo

const Footer = () => {
  return (
    <footer className="bg-[#0F172A] text-gray-300 py-10">
      <div className="container mx-auto px-6">
        {/* Newsletter Section */}
        <div className="bg-[#1E293B] p-6 rounded-lg flex flex-col md:flex-row justify-between items-center mb-10">
          <div>
            <h3 className="text-2xl font-semibold text-white">
              Subscribe to Newsletter!
            </h3>
            <p className="text-gray-400">
              Subscribe to get the latest updates and information.
            </p>
          </div>
          <form className="flex mt-4 md:mt-0">
            <input
              type="email"
              placeholder="Enter your email"
              className="py-2 px-4 rounded-l-full bg-gray-800 text-white focus:outline-none"
            />
            <button className="bg-purple-600 hover:bg-purple-700 text-white px-6 rounded-r-full">
              Subscribe
            </button>
          </form>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* Logo and Description */}
          <div className="flex flex-col items-start">
            <img src={bidzLogo} alt="BIDZ Logo" className="w-12 h-12 mb-4" />
            <h4 className="text-lg font-semibold">BIDZ</h4>
            <p
              className="mt-4 text-gray-400"
              style={{ fontSize: '17px', fontFamily: 'Urbanist, sans-serif' }}
            >
              "Empowering Global Trade with Trust, Innovation, and Opportunity — Welcome to the BIDZ Ecosystem."
            </p>
          </div>

          {/* Ecosystem Links */}
          <div>
            <h4 className="text-lg font-semibold">BIDZ Ecosystem</h4>
            <ul className="mt-4 space-y-2 text-gray-400">
              <li>
                <a href="/" className="hover:text-white">
                  ➤ Swap
                </a>
              </li>
              <li>
                <a href="/" className="hover:text-white">
                  ➤ Pay
                </a>
              </li>
              <li>
                <a href="/" className="hover:text-white">
                  ➤ VPN
                </a>
              </li>
              <li>
                <a href="/" className="hover:text-white">
                  ➤ Gamify
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Details */}
          <div>
            <h4 className="text-lg font-semibold">Contact Details</h4>
            <ul className="mt-4 space-y-2 text-gray-400">
              <li className="flex items-center">
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                <span>support@bidz.store</span>
              </li>
              <li className="flex items-center">
                <FontAwesomeIcon icon={faPhone} className="mr-2" />
                <span>+964 750 141 74-46</span>
              </li>
            </ul>
          </div>

          {/* App Download and Social Icons */}
          <div>
            <h4 className="text-lg font-semibold">Download BIDZ App's</h4>
            <div className="flex space-x-4 mt-4">
              <img src={appStoreImg} alt="App Store" className="w-32" />
              <img src={playStoreImg} alt="Google Play" className="w-32" />
            </div>
            <div className="flex space-x-4 mt-6">
              <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faTwitter} size="lg" />
              </a>
              <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faInstagram} size="lg" />
              </a>
              <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                <FontAwesomeIcon icon={faLinkedin} size="lg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;