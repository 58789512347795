import React from 'react';
import { WagmiConfig, configureChains, createClient } from 'wagmi';
import { RainbowKitProvider, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import WalletConnect from './components/WalletConnect';
import Navbar from './components/partials/Navbar';
import Footer from './components/partials/Footer';

// Set up the chains and providers
const { chains, provider } = configureChains(
  [mainnet],
  [publicProvider()]
);

// Use getDefaultWallets to handle standard and WalletConnect wallets
const { connectors } = getDefaultWallets({
  appName: 'BIDZ Token Exchange',
  chains,
});

// Create a Wagmi client with automatic connection support
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <div className="min-h-screen flex flex-col bg-gray-100 dark:bg-gray-900">
          <Navbar />
          <main className="flex-grow flex flex-col items-center justify-center py-8">
            <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-8">BIDZ Token Exchange</h1>
            <WalletConnect />
          </main>
          <Footer />
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;