import React, { useEffect, useState, useMemo } from 'react';
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from 'wagmi';
import { configureChains, WagmiConfig, createClient } from 'wagmi';
import { RainbowKitProvider, ConnectButton, getDefaultWallets } from '@rainbow-me/rainbowkit';
import { mainnet } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import Web3 from 'web3';
import axios from 'axios';
import ExchangeForm from './ExchangeForm';

// Manually configure Binance Smart Chain
const bsc = {
  id: 56,
  name: 'Binance Smart Chain',
  network: 'bsc',
  nativeCurrency: {
    name: 'Binance Coin',
    symbol: 'BNB',
    decimals: 18,
  },
  rpcUrls: {
    default: 'https://bsc-dataseed.binance.org/',
  },
  blockExplorers: {
    default: { name: 'BscScan', url: 'https://bscscan.com' },
  },
};

// Configure chains to include BSC and mainnet
const { chains, provider } = configureChains([bsc, mainnet], [publicProvider()]);

// Use getDefaultWallets for all supported wallets (MetaMask, Coinbase, WalletConnect, Trust Wallet, OKX)
const { connectors } = getDefaultWallets({
  appName: 'BIDZ Token Exchange',
  chains,
});

// Create a Wagmi client
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const WalletConnect = () => {
  const { address, isConnected } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { disconnect } = useDisconnect();

  const [web3, setWeb3] = useState(null);
  const [balance, setBalance] = useState(null);
  const [bidzCoinBalance, setBidzCoinBalance] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const bidzTokenAddress = '0x20de6118c3672659e488d1d45279cdf77391fbdc';
  const bidzTokenAbi = useMemo(() => [
    {
      constant: false,
      inputs: [{ name: 'recipient', type: 'address' }, { name: 'amount', type: 'uint256' }],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function',
    },
    {
      constant: true,
      inputs: [{ name: 'account', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: '', type: 'uint256' }],
      type: 'function',
    },
  ], []);

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum);
      setWeb3(web3Instance);
    } else {
      alert('Please install a wallet to use this feature.');
    }
  }, []);

  useEffect(() => {
    if (isConnected && chain?.id !== bsc.id && switchNetwork) {
      switchNetwork(bsc.id);
    }
  }, [isConnected, chain, switchNetwork]);

  useEffect(() => {
    const fetchBalance = async (walletAddress) => {
      try {
        const response = await axios.get(`http://92.204.129.69:3007/api/exchange/balance/wallet/${walletAddress}`);
        setBalance(response.data.balance);
      } catch {
        setErrorMessage('Failed to fetch balance.');
      }
    };

    const fetchBidzCoinBalance = async (walletAddress) => {
      try {
        const bidzContract = new web3.eth.Contract(bidzTokenAbi, bidzTokenAddress);
        const balance = await bidzContract.methods.balanceOf(walletAddress).call();
        const bidzBalance = web3.utils.fromWei(balance, 'ether');
        setBidzCoinBalance(bidzBalance);
      } catch {
        setErrorMessage('Failed to fetch BIDZ coin balance.');
      }
    };

    if (address && web3 && chain?.id === bsc.id) {
      fetchBalance(address);
      fetchBidzCoinBalance(address);
    }
  }, [address, web3, chain, bidzTokenAbi]);

  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}>
        <div className="w-full max-w-4xl mx-auto bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8">
          {!isConnected ? (
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Connect to Wallet</h2>
              <ConnectButton />
            </div>
          ) : (
            <div className="text-center">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Connected to Wallet</h2>
              <p className="text-gray-700 dark:text-gray-300">Wallet Address: <span className="font-mono">{address}</span></p>
              {balance !== null ? (
                <p className="mt-4 text-gray-700 dark:text-gray-300">My BIDZ Balance: <span className="font-bold">{balance}</span></p>
              ) : (
                <p className="mt-4 text-gray-500 dark:text-gray-400">Loading backend balance...</p>
              )}
              {bidzCoinBalance !== null ? (
                <p className="mt-4 text-gray-700 dark:text-gray-300">On-chain BIDZ Coin Balance: <span className="font-bold">{bidzCoinBalance}</span></p>
              ) : (
                <p className="mt-4 text-gray-500 dark:text-gray-400">Loading on-chain BIDZ coin balance...</p>
              )}
              <div className="mt-6">
                <ExchangeForm walletAddress={address} />
                <button onClick={disconnect} className="w-full py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg transition mt-4">
                  Disconnect
                </button>
              </div>
            </div>
          )}
          {errorMessage && <p className="mt-4 text-center text-red-500">{errorMessage}</p>}
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
};

export default WalletConnect;