import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWallet, faSearch } from '@fortawesome/free-solid-svg-icons';
import profileImage from '../assets/avatar/1.jpg'; // replace with the actual path to the profile image
import bidzLogo from '../assets/logo.png'; // replace with the actual path to the BIDZ logo

const Navbar = () => {
  return (
    <nav className="bg-[#0F172A] py-4">
      <div className="container mx-auto flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center space-x-2">
          <img src={bidzLogo} alt="BIDZ Logo" className="w-8 h-8" />
          <span className="text-white font-semibold text-xl">BIDZ</span>
        </div>

        {/* Menu Links */}
        <div className="hidden md:flex items-center space-x-8">
          <a href="/" className="text-white hover:text-gray-400">Home</a>
          <div className="relative group">
            <button className="text-white hover:text-gray-400">Pages</button>
            <div className="absolute left-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg hidden group-hover:block">
              <a href="/about" className="block px-4 py-2 hover:bg-gray-100">About Us</a>
              <a href="/contact" className="block px-4 py-2 hover:bg-gray-100">Contact</a>
              {/* Add more items as needed */}
            </div>
          </div>
          <a href="/wallet-connect" className="text-white hover:text-gray-400">Wallet Connect</a>
          <div className="relative group">
            <button className="text-white hover:text-gray-400">Ecosystem</button>
            <div className="absolute left-0 mt-2 w-40 bg-white text-black rounded-md shadow-lg hidden group-hover:block">
              <a href="/ecosystem1" className="block px-4 py-2 hover:bg-gray-100">Ecosystem 1</a>
              <a href="/ecosystem2" className="block px-4 py-2 hover:bg-gray-100">Ecosystem 2</a>
              {/* Add more items as needed */}
            </div>
          </div>
          <a href="/partnership" className="text-white hover:text-gray-400">Partnership</a>
        </div>

        {/* Search, Wallet, and Profile */}
        <div className="flex items-center space-x-4">
          {/* Search */}
          <div className="relative">
            <input
              type="text"
              placeholder="Search..."
              className="bg-[#1f2937] text-white py-2 pl-8 pr-4 rounded-full focus:outline-none"
            />
            <FontAwesomeIcon icon={faSearch} className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
          </div>

          {/* Wallet Icon */}
          <button className="bg-purple-600 p-3 rounded-full text-white hover:bg-purple-700 transition">
            <FontAwesomeIcon icon={faWallet} />
          </button>

          {/* Profile Picture */}
          <img src={profileImage} alt="Profile" className="w-10 h-10 rounded-full border-2 border-purple-600" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;